import React from 'react';
import classNames from 'classnames';
import { borderClasses, useUISettings } from 'uiSettings';

import Link from 'common/Link';
import Typography from 'common/Typography';

import { convertTypography } from 'utils/typography';

import { BadgeProps, DefaultBadgeUIType } from './models.d';

import './Badge.scss';

const Badge = ({ label, url, ui, className }: BadgeProps) => {
  const { variant: defaultBadge } = useUISettings<DefaultBadgeUIType>(
    ui.variant,
    ui?.structureAlias
  );

  const border = borderClasses(defaultBadge);

  const badgeClasses = classNames('badge', className, border, {
    [`badge--${ui?.variant}`]: ui?.variant,
    [`badge--space-${ui?.space || defaultBadge?.space}`]: ui?.space || defaultBadge?.space,
    [`background--${ui?.backgroundColor || defaultBadge?.backgroundColor}`]:
      ui?.backgroundColor || defaultBadge?.backgroundColor,
  });

  return (
    <div className={badgeClasses} data-testid="badge-item">
      {url ? (
        <Link url={url} {...(convertTypography(ui?.typography, defaultBadge?.typography) || null)}>
          {label}
        </Link>
      ) : (
        <Typography {...(convertTypography(ui?.typography, defaultBadge?.typography) || null)}>
          {label}
        </Typography>
      )}
    </div>
  );
};

export default Badge;
