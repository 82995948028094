import React, { FC } from 'react';
import classNames from 'classnames';

import { Container } from 'layout';
import Accordion from 'common/Accordion';
import IconTile from 'components/IconTile';

import { ProductSpecProps } from './models';

import './ProductSpec.scss';

const ProductSpec: FC<ProductSpecProps> = ({ accordions, backgroundColor, className }) => {
  if (!accordions) return null;

  const productSpecClass = classNames(
    'product-spec',
    {
      [`background--${backgroundColor}`]: backgroundColor,
    },
    className
  );

  return (
    <Container>
      <div className={productSpecClass}>
        {accordions?.accordionList?.map((accordion) => (
          <Accordion {...accordion} key={accordion.visibleContent} />
        ))}
        <div className="product-spec__icon-section">
          {accordions?.icon?.map(({ icon, title }) => (
            <IconTile key={title} {...{ icon, title, positionMobile: 'left' }} />
          ))}
        </div>
      </div>
    </Container>
  );
};

export default ProductSpec;
