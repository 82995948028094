import React, { FC, useState } from 'react';
import classNames from 'classnames';

import Button from 'common/Button';
import Icon from 'common/Icon';
import Typography from 'common/Typography';

import { toggleState } from 'utils/toggleState';

import { AccordionProps } from './models.d';

import './Accordion.scss';

const Accordion: FC<AccordionProps> = ({ visibleContent, hiddenContent, className }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const accordionClasses = classNames('accordion', className, {
    'accordion--open': isOpen,
  });

  const accordionButtonClasses = classNames('accordion__button', {
    'total-disabled--child': !isOpen,
  });

  const renderedAccordion = (
    <>
      <Button
        ui={{
          variant: 'simple',
          space: 'between',
          typography: {
            weight: 'bold',
            align: 'left',
            color: 'deep-koamaru',
            size: { base: 20, md: 60 },
            lineHeight: 'xsmall',
            as: 'h2',
            padding: { bottom: { base: 'md', xl: 'xxl' }, top: { base: 'md', xl: 'xxl' } },
          },
        }}
        className={accordionButtonClasses}
        onClick={() => toggleState(setIsOpen)}
      >
        {visibleContent}
        <Icon icon="arrow-down" className="accordion__icon" />
      </Button>
      <div className="accordion__hidden-content">
        <div className="accordion__description">
          {typeof hiddenContent === 'string' ? (
            <Typography
              dangerouslySetInnerHTML={hiddenContent}
              size={{ base: 15, md: 20 }}
              color="mine-shaft"
              padding={{ bottom: 'xxl' }}
              lineHeight="small"
            />
          ) : (
            hiddenContent
          )}
        </div>
      </div>
    </>
  );

  return (
    <div className={accordionClasses} data-testid="accordion-item">
      {renderedAccordion}
    </div>
  );
};

export default Accordion;
