import React, { FC } from 'react';
import classNames from 'classnames';

import Badge from 'common/Badge';
import Image from 'common/Image';
import Typography from 'common/Typography';
import IconTile from 'components/IconTile';
import Modal from 'components/Modal';

import { useScreenService } from 'hooks/useScreenService';

import { ProductDetailsProps } from './models';

import './ProductDetails.scss';

const ProductDetails: FC<ProductDetailsProps> = ({
  themeColor,
  productName,
  productDescription,
  descriptionBadge,
  productDescriptionLong,
  image,
  iconSection,
  badge,
  features,
  className,
  button,
}) => {
  const { isSm, isMd } = useScreenService();

  return (
    <div className={classNames('product-details', className)}>
      {image ? (
        <div
          className={classNames('product-details__packshot', {
            [`background--${themeColor}`]: themeColor,
          })}
        >
          <Image objectFit="contain" {...image.imageStructure} />
        </div>
      ) : null}

      {button?.buttons?.length ? (
        <Modal modalClassName="product-details__modal" {...{ button }} />
      ) : null}

      {productName ? (
        <Typography
          as="h1"
          weight="bold"
          color="deep-koamaru"
          size={{ base: 35, lg: 60 }}
          className="product-details__name"
          lineHeight="xsmall"
        >
          {productName}
        </Typography>
      ) : null}

      {descriptionBadge ? (
        <Badge className="product-details__description-badge" {...descriptionBadge} />
      ) : null}

      {productDescription ? (
        <Typography
          size={{ base: 16, md: 20 }}
          className="product-details__description"
          dangerouslySetInnerHTML={productDescription}
        />
      ) : null}
      {productDescriptionLong ? (
        <Typography
          size={{ base: 16, md: 20 }}
          className="product-details__description-long"
          dangerouslySetInnerHTML={productDescriptionLong}
        />
      ) : null}

      {iconSection ? (
        <section className="product-details__icon-section">
          {iconSection.map(({ icon, title, positionMobile, positionDesktop }) => (
            <div
              className={classNames(
                'product-details__icon-wrapper',

                {
                  [`product-details__icon-wrapper--${
                    isSm || isMd ? positionMobile : positionDesktop
                  }`]: positionMobile || positionDesktop,
                }
              )}
              key={title}
            >
              <IconTile
                icon={icon}
                title={title}
                iconClassName="product-details__icon"
                positionDesktop={positionDesktop || undefined}
                positionMobile={positionMobile || undefined}
                key={icon}
                size={16}
                showMobileVersion={isSm || isMd}
              />
            </div>
          ))}
        </section>
      ) : null}
      {badge ? <Badge className="product-details__badge" {...badge} /> : null}
      {features ? (
        <div className="product-details__features">
          <Typography
            className="product-details__features-title"
            color="deep-koamaru"
            weight="bold"
            size={{ base: 15, md: 20 }}
          >
            {features.featuresName}
          </Typography>

          <div className="product-details__features-badges">
            {features.badge?.map((item) => (
              <Badge {...item} key={item.label} />
            ))}
          </div>
          {features.disclaimer ? (
            <Typography
              className="product-details__features-title"
              color="mine-shaft"
              dangerouslySetInnerHTML={features.disclaimer}
              size={12}
            />
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default ProductDetails;
