import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Layout from 'layout/Layout';
import Banner from 'components/Banner';
import Infographics from 'components/Infographics';
import ProductDetails from 'containers/ProductDetails';
import ProductSpec from 'containers/ProductSpec';
import RelatedList from 'containers/RelatedList';
import { handleProductVariant } from 'containers/RelatedList/utils';

import './ProductDetailPage.scss';

const ProductDetailPage: FC<ProductDetailPageTypes.ProductDetailPageProps> = ({
  data: {
    productDetailPage: {
      urls,
      seo,
      themeColor,
      productName,
      productDescription,
      descriptionBadge,
      productDescriptionLong,
      image,
      iconSection,
      badge,
      features,
      infographic,
      footerBanner,
      accordions,
      relatedProducts,
      button,
    },
    allProductDetailPage: { productsList },
  },
}) => (
  <Layout {...{ urls, seo }} background="tutu">
    <ProductDetails
      {...{
        themeColor,
        productName,
        productDescription,
        descriptionBadge,
        productDescriptionLong,
        image,
        iconSection,
        badge,
        features,
        button,
      }}
    />
    {accordions ? (
      <div className="product-detail-page__content">
        <ProductSpec {...{ accordions }} backgroundColor={themeColor} />
      </div>
    ) : null}
    {infographic?.length ? (
      <Infographics
        {...{ infographics: infographic }}
        variant="fluid"
        className="product-detail-page__infographics"
      />
    ) : null}
    <div className="product-detail-page__content">
      <RelatedList {...relatedProducts} relatedItems={handleProductVariant(productsList)} />
    </div>
    <div className="product-detail-page__banner">
      {footerBanner ? <Banner {...footerBanner} /> : null}
    </div>
  </Layout>
);

export const query = graphql`
  query ProductDetailPageQuery($lang: String, $path: [String], $productsUrls: [String]) {
    productDetailPage(lang: { eq: $lang }, url: { in: $path }) {
      seo {
        ...SEOStructureFragment
      }
      urls {
        ...UrlsFragment
      }
      pageName
      infographic {
        ...InfographicFragment
      }
      footerBanner {
        ...BannerFragment
      }
      themeColor
      productName
      productDescription
      descriptionBadge {
        ...BadgeFragment
      }
      productDescriptionLong
      image {
        ...TransparentImageStructureFragment
      }
      iconSection {
        ...IconListFragment
      }
      badge {
        ...BadgeFragment
      }
      features {
        ...FeaturesFragment
      }
      accordions {
        ...AccordionListFragment
      }
      relatedProducts {
        ...RelatedListFragment
      }
      button {
        ariaLabel
        label
        url
        ui {
          ...ButtonUIFragment
        }
        image {
          ...TransparentImageStructureFragment
        }
        icon {
          ...IconListFragment
        }
        buttons {
          ...ButtonFragment
        }
        windowText
      }
    }
    allProductDetailPage(filter: { url: { in: $productsUrls } }) {
      productsList: nodes {
        ...ProductCardFragment
      }
    }
  }
`;

export default ProductDetailPage;
