import React, { FC, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';

import Button from 'common/Button';
import Icon from 'common/Icon';
import Typography from 'common/Typography';

import { ModalPropsType } from './model';

import './Modal.scss';

const Modal: FC<ModalPropsType> = ({ button, modalClassName }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { buttons, windowText, ...openButton } = button;

  const contentClassNames = classNames('modal__content', {
    'modal__content--visible': isOpen,
  });

  const iconClassNames = classNames('modal__btn-arrow', {
    'modal__btn-arrow--active': isOpen,
  });

  const handleOverlay = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const escFunction = useCallback(
    (event) => {
      if (event.key === 'Escape') handleOverlay();
    },
    [handleOverlay]
  );

  useEffect(() => {
    document.addEventListener('keydown', escFunction);

    return () => {
      document.removeEventListener('keydown', escFunction);
    };
  }, [escFunction]);

  useEffect(() => {
    const bodyElement = window.document.body;

    if (isOpen) {
      bodyElement.classList.add('scroll--prevent');
    } else {
      bodyElement.classList.remove('scroll--prevent');
    }
  }, [isOpen]);

  return (
    <div className={classNames('modal', modalClassName)}>
      <Button
        {...openButton}
        onClick={() => setIsOpen((prevIsOpen) => !prevIsOpen)}
        disabled={!buttons.length}
      >
        {openButton.label}
        <Icon className={iconClassNames} icon="arrow-down" />
      </Button>
      <div className={contentClassNames}>
        <Button
          className="modal__close-btn"
          onClick={() => setIsOpen(false)}
          ui={{ variant: 'primary' }}
        />
        <Typography
          size={{ base: 32, xs: 40 }}
          align="center"
          color="mine-shaft"
          className="modal__heading"
          weight="bold"
        >
          {windowText}
        </Typography>
        <div className="modal__buttons">
          {buttons.map((availableButton) =>
            availableButton?.image ? (
              <div key={availableButton.image.imageStructure.alt} className="modal__button">
                <Button {...{ ...availableButton }}>{availableButton.label}</Button>
              </div>
            ) : null
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;
